@import "colors";

body {
  margin: 0;
  padding: 0;
  font-family: 'open-sans', serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: $bg-primary;
  color: $primary;
  min-width: 300px;
}

h1 {
  font-family: 'orpheuspro', serif;
  font-weight: 700;
  color: $olive;
  font-size: 36px;
}

h2 {
  font-weight: 500;
  font-size: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-style: normal;
  font-size: 18px;
}

a, .link {
  color: $olive;
  text-decoration: none;
  font-weight: 600;
  transition: color 2s;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: $light-olive;
  }
  cursor: pointer;
}

.ita {
  font-style: italic;
}

.inline-link, .courselink {
  &:hover {
    text-decoration: underline;
  }
}

.landing {
  margin-left: 25vw;
  margin-right: 25vw;
  margin-top: 20vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  .landing-text {
    width: 50%;
    padding-bottom: 50px;

    .links {
      margin-left: 0px;
      display: flex;
      flex-direction: row;

      .link {
        padding-right: 10px;
        text-align: left;

        .link-sub {
          font-size: 14px;
        }
      }
    }
  }

  .landing-image {
    width: 40%;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  .landing-image-mobile {
    display: none;
  }
}

.comp251 {
  margin-left: 25vw;
  margin-right: 25vw;
  margin-top: 10vh;
  margin-bottom: 5vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  .comp251-text {
    padding-bottom: 50px;

    ul {
      list-style-type: none;
      padding-left: 12px;

      li {
        margin-bottom: 8px;
      }
    }
  }
}

.not-found {
  margin-left: 25vw;
  margin-right: 25vw;
  margin-top: 20vh;
  text-align: center;
  justify-content: center;
  align-items: center;
}

@media (max-width: 900px) {

  p {
    font-size: 16px;
  }

  .landing {

    margin-left: 15vw;
    margin-right: 15vw;
    margin-top: 3vh;
    margin-bottom: 5vh;

    .landing-text { 
      width: 100%; 
      padding-bottom: 20px;

      .landing-cities {
        display: none;
      }
    }

    .landing-image {
      display: none;
    }

    .landing-image-mobile {
      display: flex;
      margin: auto;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }

  }

  .comp251 {

    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 3vh;
    margin-bottom: 5vh;

    .comp251-text {
      width: 100%;

      li {
        font-size: 14px;
      }

      .comp251-title {
        display: none;
      }
    }
  }

}

.rows {
  display: flex;
  flex-direction: column;
}

