// main colors
$blue: #68b6ef;
$green: #7d7;
$purple: #9BB0FF;
$pink: #DA88FF;
$navy: #0A1B29;

// background
$bg-primary: #fefefe;

// fonts
$primary: #222;
$olive: #556b2f;
$light-olive: #a4be5c;
$dark-olive: #2a3810;
$secondary: #DA88FF;